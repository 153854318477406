import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Header from './../hanzo/lib/components/components/header'
import Footer from '../hanzo/lib/components/modules/footer'

// CSS imports
import mainCSS from '../resources/CSS/main.css' // eslint-disable-line no-unused-vars
import navbarCSS from '../resources/CSS/navbar.css' // eslint-disable-line no-unused-vars
import Responsive from '../resources/CSS/responsive.css' // eslint-disable-line no-unused-vars
import NavigationResponsive from '../resources/CSS/navigation-responsive.css' // eslint-disable-line no-unused-vars
import OrderedList from '../resources/CSS/ordered-list.css' // eslint-disable-line no-unused-vars

import {
    AboutIcon,
    AskIcon,
    BlogNavIcon,
    ContactIcon,
    CustomersIcon,
    DocsIcon,
    LogoIcon,
    PricingIcon,
    ProductIcon,
    RequestDemoIcon,
    RequestQuoteIcon,
    K2KIcon,
} from '../hanzo/lib/components/basics/icons'
import Icon from '../hanzo/lib/components/basics/icon'
import { useLocation } from '@reach/router'
import { CommonRoomScript } from './common-room-script'

const navigation = [
    {
        section: 'Product',
        items: [
            {
                title: 'Lenses Overview',
                description: 'All your streams. One screen.',
                icon: <Icon icon={<ProductIcon />} size='lg' />,
                link: '/product',
            },
            {
                title: 'Try Lenses K2K',
                description: 'Universal Kafka to Kafka replication',
                icon: <Icon icon={<K2KIcon />} size='lg' />,
                link: '/kafka-replication',
            },
            {
                title: 'Request a Demo',
                description: '20 minute demo',
                icon: <Icon icon={<RequestDemoIcon />} size='lg' />,
                link: '/request-demo',
            },
            {
                title: 'Docs',
                description: 'How lenses works',
                icon: <Icon icon={<DocsIcon />} size='lg' />,
                target: '_blank',
                link: 'https://docs.lenses.io/latest',
            },
        ],
    },
    {
        section: 'Pricing',
        items: [
            {
                title: 'Pricing plans',
                description: 'Scale as you grow',
                icon: <Icon icon={<PricingIcon />} size='lg' />,
                link: '/product/pricing',
            },
            {
                title: 'Request quote',
                description: 'Get a custom quote',
                icon: <Icon icon={<RequestQuoteIcon />} size='lg' />,
                link: '/request-quote',
            },
        ],
    },
    {
        section: 'Company',
        items: [
            {
                title: 'About',
                description: 'Our story, background, beliefs',
                icon: <Icon icon={<AboutIcon />} size='lg' />,
                link: '/company/about-us',
            },
            {
                title: 'Blog',
                description: 'Musings and guides on data streaming',
                icon: <Icon icon={<BlogNavIcon />} size='lg' />,
                link: '/blog',
            },
            {
                title: 'Contact',
                description: 'Send us a message',
                icon: <Icon icon={<ContactIcon />} size='lg' />,
                link: '/contact-us',
            },
        ],
    },
    {
        section: 'Community',
        items: [
            {
                title: 'Customers',
                description: 'What they’ve achieved',
                icon: <Icon icon={<CustomersIcon />} size='lg' />,
                link: '/customers',
            },
            {
                title: 'Discourse',
                description: 'Ask about Lenses or Kafka',
                icon: <Icon icon={<AskIcon />} size='lg' />,
                link: 'https://ask.lenses.io/',
                target: '_blank',
            },
        ],
        social: [
            {
                title: 'Github',
                link: 'https://github.com/lensesio',
                target: '_blank',
            },
            {
                title: 'Slack',
                link: 'https://www.launchpass.com/lensesio',
                target: '_blank',
            },
            {
                title: 'LinkedIn',
                link: 'https://www.linkedin.com/company/lensesio/',
                target: '_blank',
            },
            {
                title: 'Youtube',
                link: 'https://www.youtube.com/channel/UCWHoDtkhWPswP5KIY6mq7RQ',
                target: '_blank',
            },
        ],
    },
]

const Layout = ({ children, isTransparent }) => {
    const [isBannerVisible, setIsBannerVisible] = useState(true)
    const [isPrimaryCtaVisible, setIsPrimaryCtaVisible] = useState(true)
    const { pathname } = useLocation()

    useEffect(() => {
        if (pathname.includes('everything-need-know-about-kafka')) {
            setIsBannerVisible(false)
        } else {
            setIsBannerVisible(true)
        }
    }, [])

    useEffect(() => {
        if (pathname.includes('community-edition')) {
            setIsPrimaryCtaVisible(false)
        } else {
            setIsPrimaryCtaVisible(true)
        }
    }, [])

    return (
        <Fragment>
            <Header
                bannerVisible={isBannerVisible}
                logo={<LogoIcon />}
                nav={navigation}
                isPrimaryCtaVisible={isPrimaryCtaVisible}
                primaryCtaLink='/community-edition/'
                primaryCtaText='Try now'
                bgColor={isTransparent ? 'transparent' : 'dark'}
            />

            {children}

            <Footer
                title={'<b>Lenses</b>, autonomy in data streaming'}
                href='/community-edition/'
                buttonText={'Try now'}
            />

            <CommonRoomScript />
        </Fragment>
    )
}

export default Layout

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    newsletter: PropTypes.bool,
    onlyLogo: PropTypes.bool,
    hasBanner: PropTypes.bool,
    isTransparent: PropTypes.bool,
    noFooter: PropTypes.bool,
    linkColor: PropTypes.string,
    logo: PropTypes.string,
    type: PropTypes.string,
}
