import React from 'react'

export const CommonRoomScript = () => {
    React.useEffect(() => {
        const scriptElement = document.createElement('script')
        scriptElement.textContent = `
        (function() {
            if (typeof window === 'undefined') return;
            if (typeof window.signals !== 'undefined') return;
            var script = document.createElement('script');
            script.src = 'https://cdn.cr-relay.com/v1/site/1f878624-8842-46c2-b5c5-5aa116d51456/signals.js';
            script.async = true;
            window.signals = Object.assign(
                [],
                ['page', 'identify', 'form'].reduce(function (acc, method){
                    acc[method] = function () {
                        signals.push([method, arguments]);
                        return signals;
                    };
                    return acc;
                }, {})
            );
            document.head.appendChild(script);
        })();
        `

        document.body.appendChild(scriptElement)

        return () => {
            document.body.removeChild(scriptElement)
        }
    }, [])

    return null
}
