import React, { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

const LensesLogo = ({ className, ...rest }: Props) => (
    <svg
        width='118'
        height='37'
        viewBox='0 0 118 37'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
        {...rest}>
        <title>Lenses Logo</title>
        <path
            d='M12.1308 3.26501C5.70753 3.26501 0.5 8.61893 0.5 15.2227C0.5 21.0952 4.62248 25.9809 10.0532 26.9864V34.2804L14.241 32.8347V26.9752C19.6536 25.9641 23.7615 21.0784 23.7615 15.2227C23.7615 8.61893 18.554 3.26501 12.1308 3.26501ZM12.1072 22.7462C8.07542 22.7462 4.81481 19.3846 4.81481 15.2488C4.81481 11.1131 8.08449 7.75148 12.1072 7.75148C16.1299 7.75148 19.3995 11.1131 19.3995 15.2488C19.3995 19.3846 16.1371 22.7462 12.1072 22.7462Z'
            fill='currentColor'
        />
        <path
            d='M112.231 28.4218C110.275 28.4162 108.765 27.9665 107.702 27.0725C106.657 26.1787 106.118 25.0155 106.088 23.583L108.682 23.5904C108.732 24.429 109.035 25.1024 109.593 25.6106C110.168 26.1014 111.041 26.3484 112.211 26.3518C113.242 26.3547 113.976 26.1734 114.414 25.8078C114.851 25.4248 115.071 24.9538 115.073 24.3948C115.074 23.8707 114.918 23.4685 114.605 23.1881C114.291 22.9077 113.951 22.7234 113.584 22.635C113.235 22.5292 112.563 22.37 111.568 22.1576C110.538 21.9101 109.683 21.6543 109.002 21.3903C108.322 21.1264 107.747 20.7317 107.276 20.2063C106.806 19.6809 106.572 18.9902 106.575 18.1343C106.578 16.9639 107.026 16.0307 107.919 15.3345C108.83 14.6384 110.08 14.2926 111.669 14.2971C113.416 14.3021 114.76 14.7078 115.701 15.514C116.642 16.3202 117.163 17.3436 117.264 18.5841L114.67 18.5767C114.55 17.93 114.272 17.4052 113.836 17.0022C113.418 16.5818 112.711 16.3701 111.716 16.3673C110.877 16.3649 110.257 16.5116 109.854 16.8074C109.451 17.1032 109.249 17.5131 109.248 18.0371C109.247 18.4214 109.359 18.7275 109.586 18.9552C109.812 19.1829 110.1 19.3584 110.449 19.4817C110.798 19.5875 111.313 19.7113 111.994 19.853C112.134 19.8708 112.343 19.9151 112.622 19.9858C113.687 20.2334 114.569 20.4979 115.267 20.7794C115.964 21.0609 116.548 21.4818 117.018 22.0421C117.506 22.6025 117.748 23.3456 117.746 24.2714C117.742 25.5815 117.259 26.602 116.296 27.3329C115.333 28.0639 113.978 28.4268 112.231 28.4218Z'
            fill='currentColor'
        />
        <path
            d='M98.454 28.4069C97.074 28.4029 95.8871 28.0938 94.8931 27.4796C93.8992 26.8654 93.1417 26.0247 92.6207 24.9577C92.1172 23.8907 91.8674 22.6672 91.8714 21.2872C91.8754 19.8898 92.1409 18.659 92.668 17.595C93.2126 16.531 93.9835 15.7034 94.9809 15.1124C95.9783 14.5213 97.1495 14.2277 98.4946 14.2315C100.503 14.2373 102.056 14.8531 103.153 16.079C104.268 17.305 104.822 18.9661 104.816 21.0622C104.815 21.5688 104.805 21.8919 104.787 22.0316L94.5158 22.0022C94.5472 23.2076 94.9024 24.2306 95.5813 25.071C96.2777 25.894 97.2547 26.3073 98.5124 26.3109C99.5605 26.3139 100.365 26.0629 100.925 25.5579C101.503 25.053 101.871 24.5038 102.03 23.9103L104.651 23.9178C104.262 25.2792 103.552 26.369 102.519 27.187C101.486 28.0051 100.131 28.4117 98.454 28.4069ZM102.146 20.0065C102.149 18.9409 101.803 18.0665 101.106 17.3833C100.427 16.6826 99.5279 16.3307 98.41 16.3275C97.3269 16.3244 96.4263 16.6624 95.7082 17.3416C94.9901 18.0034 94.5945 18.8844 94.5215 19.9847L102.146 20.0065Z'
            fill='currentColor'
        />
        <path
            d='M85.0058 28.341C83.0493 28.3354 81.5396 27.8856 80.4766 26.9917C79.4311 26.0979 78.8929 24.9347 78.8621 23.5022L81.4561 23.5096C81.5061 24.3482 81.8098 25.0216 82.3674 25.5298C82.9424 26.0206 83.8151 26.2676 84.9855 26.271C86.0161 26.2739 86.7503 26.0926 87.1881 25.727C87.6259 25.344 87.8456 24.8729 87.8472 24.314C87.8487 23.7899 87.6926 23.3877 87.379 23.1073C87.0653 22.8269 86.7252 22.6425 86.3587 22.5542C86.0096 22.4483 85.3375 22.2892 84.3424 22.0767C83.3125 21.8292 82.4573 21.5735 81.7768 21.3095C81.0963 21.0456 80.521 20.6509 80.0508 20.1255C79.5807 19.6001 79.3468 18.9094 79.3493 18.0535C79.3526 16.8831 79.8007 15.9499 80.6936 15.2537C81.604 14.5576 82.8539 14.2118 84.4435 14.2163C86.1904 14.2213 87.5342 14.6269 88.4752 15.4332C89.4162 16.2394 89.9373 17.2628 90.0386 18.5033L87.4446 18.4959C87.3241 17.8492 87.0462 17.3244 86.6106 16.9214C86.1926 16.5009 85.4857 16.2893 84.49 16.2865C83.6515 16.2841 83.031 16.4308 82.6284 16.7266C82.2258 17.0224 82.0237 17.4323 82.0222 17.9563C82.0211 18.3406 82.1338 18.6467 82.3602 18.8744C82.5867 19.1021 82.8744 19.2776 83.2234 19.4009C83.5725 19.5067 84.0874 19.6305 84.7683 19.7722C84.908 19.79 85.1175 19.8343 85.3968 19.905C86.4616 20.1526 87.343 20.4171 88.0409 20.6986C88.7388 20.9801 89.3228 21.401 89.7929 21.9613C90.2804 22.5217 90.5228 23.2648 90.5202 24.1906C90.5164 25.5007 90.0331 26.5212 89.0703 27.2521C88.1074 27.983 86.7526 28.346 85.0058 28.341Z'
            fill='currentColor'
        />
        <path
            d='M74.4193 19.3788C74.422 18.4355 74.197 17.7012 73.7443 17.1759C73.3091 16.6506 72.6286 16.3866 71.7028 16.384C70.6023 16.3808 69.7193 16.684 69.0538 17.2935C68.4057 17.903 68.0802 18.7143 68.0773 19.7275L68.0538 27.9287L65.486 27.9214L65.5243 14.5583L68.0921 14.5657L68.0873 16.2426L68.1397 16.2427C68.5432 15.65 69.1123 15.1625 69.847 14.7803C70.5818 14.3806 71.482 14.1823 72.5476 14.1854C73.8577 14.1891 74.9221 14.5765 75.7409 15.3474C76.5773 16.1009 76.9933 17.2114 76.9891 18.6787L76.9626 27.9542L74.3948 27.9469L74.4193 19.3788Z'
            fill='currentColor'
        />
        <path
            d='M56.9755 28.2873C55.5955 28.2833 54.4085 27.9742 53.4146 27.36C52.4207 26.7458 51.6632 25.9051 51.1422 24.8381C50.6387 23.7711 50.3889 22.5476 50.3928 21.1676C50.3968 19.7701 50.6624 18.5394 51.1895 17.4753C51.734 16.4113 52.505 15.5838 53.5024 14.9927C54.4998 14.4017 55.671 14.1081 57.016 14.1119C59.0249 14.1177 60.5778 14.7335 61.6748 15.9594C62.7892 17.1854 63.3435 18.8464 63.3375 20.9426C63.336 21.4492 63.3264 21.7723 63.3085 21.912L53.0372 21.8826C53.0687 23.088 53.4239 24.1109 54.1028 24.9513C54.7991 25.7743 55.7762 26.1876 57.0339 26.1912C58.082 26.1942 58.8862 25.9432 59.4467 25.4383C60.0246 24.9333 60.393 24.3842 60.5519 23.7907L63.1721 23.7982C62.7839 25.1596 62.0733 26.2493 61.0403 27.0674C60.0074 27.8854 58.6524 28.2921 56.9755 28.2873ZM60.6679 19.8869C60.6709 18.8213 60.324 17.9469 59.6273 17.2637C58.948 16.563 58.0494 16.2111 56.9314 16.2079C55.8484 16.2048 54.9478 16.5428 54.2297 17.222C53.5116 17.8838 53.116 18.7648 53.043 19.8651L60.6679 19.8869Z'
            fill='currentColor'
        />
        <path
            d='M37.6074 9.1062L40.4897 9.11445L40.4432 25.3598L49.9284 25.3869L49.9213 27.8761L37.5538 27.8407L37.6074 9.1062Z'
            fill='currentColor'
        />
    </svg>
)

export default LensesLogo
