import React from 'react'

const K2KIcon = () => (
    <svg
        width='213'
        height='187'
        viewBox='0 0 213 187'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M185.955 49.2161C172.367 35.6283 155.04 28.0464 137.29 26.46C137.352 26.5218 137.414 26.5836 137.476 26.6454C168.236 57.4058 168.236 107.286 137.476 138.057C120.303 155.229 97.166 162.811 74.7292 160.813C105.51 191.388 155.246 191.336 185.945 160.627C216.705 129.867 216.705 79.9868 185.945 49.2161H185.955Z'
            stroke='#2D2D2D'
            strokeWidth='6'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M74.5541 49.2161C91.7268 32.0434 114.864 24.4615 137.301 26.46C106.52 -4.11499 56.7839 -4.06347 26.0853 26.6454C-4.67509 57.4058 -4.67509 107.286 26.0853 138.057C39.6731 151.644 57.0003 159.226 74.7498 160.813C74.688 160.751 74.6262 160.689 74.5644 160.627C43.804 129.867 43.804 79.9868 74.5644 49.2161H74.5541Z'
            stroke='#2D2D2D'
            strokeWidth='6'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M74.5541 49.2161C43.7937 79.9765 43.7937 129.857 74.5541 160.627C74.6159 160.689 74.6777 160.751 74.7395 160.813C97.1763 162.811 120.303 155.229 137.486 138.057C168.247 107.296 168.247 57.4161 137.486 26.6454C137.424 26.5836 137.363 26.5218 137.301 26.46C114.864 24.4615 91.7371 32.0434 74.5541 49.2161Z'
            stroke='#FF4802'
            strokeWidth='6'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default K2KIcon
